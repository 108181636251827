@font-face {
  font-family: "Quicksand";
  src: url('./quicksand//Quicksand-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
    font-family: "Quicksand";
    src: url('./quicksand//Quicksand-Medium.ttf') format('truetype');
    font-weight: 400;
    font-style: medium;
  }
  
  @font-face {
    font-family: "Quicksand";
    src: url('./quicksand//Quicksand-Bold.ttf') format('truetype');
    font-weight: 400;
    font-style: bold;
  }

  @font-face {
    font-family: "Poppins";
    src: url('./Poppins/Poppins-Black.ttf') format('truetype');
    font-weight: 400;
    font-style: black;
  }
  
  @font-face {
    font-family: "Poppins";
    src: url('./Poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: 400;
    font-style: bold;
  }

  @font-face {
    font-family: "Poppins";
    src: url('./Poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: 400;
    font-style: medium;
  }

  @font-face {
    font-family: "Poppins";
    src: url('./Poppins/Poppins-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: light;
  }