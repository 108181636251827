:root {
    --purple-1: #523838;
    --purple-2: #4f3e3e;
    --red-1: #ebd078;
    --left-1: #e6d5c3;
    --right-1: #895c5c;
    --left-2: #ebd078;
    --right-2: #a37878;
    --light-grey: #ffffff;
    --bggrey: #ffffff;

}

/* test voor svg font */

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
    /* You can add additional font weights and styles here if needed */
  }
/* all the font styles */
.headerstyle { 
  font-family: Poppins;
  font-weight: normal;
  font-size: 2em;
  text-align: center;
}

.h02 {
    font-family: Poppins;
    font-weight: 200;
    font-size: 2em;
    text-align: center;
}

.p01 {
    font-family: Poppins;
    font-weight: 200;
    font-size: 1.2em;
    text-align: left;
}

/* mobile headers */
.headerstylemobile{
    font-family: Poppins;
    font-size: 1.5em;
    text-align: center;
    font-weight: 150;
}


.lineargradient {
    background: linear-gradient(30deg, var(--left-1), var(--right-1));
    color: transparent !important;
    background-clip: text !important;
    -webkit-background-clip: text;
}

.lineargradient2, .lineargradient::selection, .lineargradient::-webkit-selection {
    background: linear-gradient(30deg, var(--left-2), var(--right-2));
    color: transparent !important;
    background-clip: text !important;
    -webkit-background-clip: text;
}

.custombutton {
    background: linear-gradient(30deg, var(--red-1), var(--right-1)) !important;
    color: white !important;
}

.custombutton:hover {
    background: linear-gradient(120deg, var(--red-1), var(--right-1)) !important;
    color: white !important;
}

.herosection {
    padding: 50px 0;
    text-align: center;
    background-color: #a4b2a4;
    height: 50vh;
    max-height: 600px;
    /* Add more styles here */
}

.custommenu {
    color: white;
}


/* menu kleuren en layout voor de knopjes */
.menup {
    color: #e6d5c3;
    font-family: Montserrat, Arial, sans-serif;
  }

/* menu kleuren en layout voor de knopjes */
.pages {
    background-color: #666666;
    font-family: Montserrat, Arial, sans-serif;
  }

  .imgcircle {
    display: inline-block;
    padding-bottom: 2em; /* Use camelCase for property names */
    width: 70%; /* No quotes around numeric values */
    border-radius: 50%; /* No quotes around numeric values */
    overflow: hidden; /* No quotes needed */
}

.centered {
    display: inline-flex;
    justify-items: center;
    align-content:center;
}

.nolines{ 
    position: relative;
    height: 100vh;
    overflow: hidden; 
    padding: 0em;
}
