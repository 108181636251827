body {
    background-color: #ffffff;
    margin: 0;
    font-family:
        "Poppins",
        "Quicksand",
            "Roboto",
        "Rubik",
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

